import { webSourceEscortSiteOptions, webSourceFieldLabels, webSourceFieldPlaceholders } from './webSourceFields';

export function readableFormat(fieldName) {
  if (!fieldName) {
    return fieldName;
  }

  return (
    fieldName
      .split(/(?=[A-Z])/)
      .join(' ')
      // capitalize first letter of first word
      .replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1))
  );
}

export const webSourceFieldLabel = field => webSourceFieldLabels[field] || readableFormat(field);
export const webSourceFieldPlaceholder = field => webSourceFieldPlaceholders[field] || readableFormat(field);

export const fieldValue = (uniqueFieldKey, keyValue) => {
  if (!uniqueFieldKey) {
    return keyValue;
  }

  if (uniqueFieldKey === 'escortSiteTitle') {
    const escortSiteOption = webSourceEscortSiteOptions.find(({ value }) => value === keyValue);

    if (escortSiteOption) {
      return escortSiteOption.label;
    }
  }

  // Default
  return keyValue;
};

export const usesOldEscortAdFormat = record => {
  const { escortAd } = record;

  if (!escortAd) {
    return false;
  }
  const escortAdData = [escortAd.url, ...escortAd.phones.map(({ phone }) => phone), escortAd.description];
  const escortAdEmpty = escortAdData.filter(Boolean).length === 0;

  // console.log({ escortAdEmpty, escortAdData });

  // If all fields are empty, it's the old format
  return !escortAdEmpty;
};
