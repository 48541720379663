import React, { useState, useEffect } from 'react';
import * as Icons from 'react-icons/fa';
import { AwardClassificationOptions } from '../../constants';

const PointBreakdown = ({ caseRecord, expandAll, hideExpand }) => {
  const [expanded, setExpanded] = useState(false);
  const [leadApprovedPts, setLeadApprovedPts] = useState(0);
  const [sourcePoints, setSourcePoints] = useState(caseRecord?.totalPoints?.pointsAwarded || 0)

  useEffect(() => {
    const { wasReturned } = caseRecord;
    const basePoints = caseRecord?.totalPoints?.pointsAwarded || 0;
    if (caseRecord.awardClassificationOption === AwardClassificationOptions.Underage) {
      if (wasReturned) {
        setLeadApprovedPts(25);
        // setSourcePoints(sourcePoints - 25);
        setSourcePoints(basePoints - 25);
      } else {
        setLeadApprovedPts(50);
        // setSourcePoints(sourcePoints - 50);
        setSourcePoints(basePoints - 50);
      }
    } else if (caseRecord.awardClassificationOption === AwardClassificationOptions.Adult) {
      if (wasReturned) {
        setLeadApprovedPts(3);
        // setSourcePoints(sourcePoints - 3);
        setSourcePoints(basePoints - 3);
      } else {
        setLeadApprovedPts(5);
        // setSourcePoints(sourcePoints - 5);
        setSourcePoints(basePoints - 5);
      }
    }
  }, [caseRecord]);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  const expandArrow = expanded ? <Icons.FaChevronDown /> : <Icons.FaChevronRight />;
  const divHeight = expanded ? 'max-h-64r' : 'max-h-0';
  const expandedBox = expanded ? 'shadow pb-6' : '';
  const awardType = caseRecord.awardClassificationOption === AwardClassificationOptions.Underage ? 'Underage' : 'Adult';

  return (
    <>
      <div className={`mb-4 font-bold transition-transform duration-500 transform ${expandedBox}`}>
        <div className="flex flex-row">
          <div className="flex flex-row justify-between w-full p-2 text-white bg-guardian-darkblue">
            <p>Points Awarded: {caseRecord?.totalPoints?.pointsAwarded || 0}</p>
            <div className="flex">
              <button onClick={toggleExpanded} type="button" className="focus:outline-none">
                {!hideExpand && expandArrow}
              </button>
            </div>
          </div>
        </div>
        <div className={`px-12 pt-4 duration-700 ease-in-out transition-maxheight ${divHeight}`}>
          {!expanded ? (
            <></>
          ) : (
            <div className={`${divHeight}`}>
              <ul>
                <li>
                  {awardType} Lead Approved: {leadApprovedPts}{' '}
                </li>
                <li>Escort Ad and Web Sources: {sourcePoints}</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PointBreakdown;
