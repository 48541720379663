/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import states from 'states-us';

const PersonalInfoForm = ({ caseState, setCaseState, reviewing, disableNameEdit = false }) => {
  function setInput(key, value) {
    setCaseState({ ...caseState, [key]: value });
  }

  return (
    <>
      <div className="mb-8">
        <h2 className="text-lg font-bold text-pursuit-gray">Personal Information</h2>
        <div className="text-sm text-pursuit-gray">
          {!reviewing && (
            <p>
              Fill out as much information as you can. You can always come back to this screen before submitting. First
              and last name are required.
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        <label className="text-pursuit-gray">
          First Name<span className="ml-1 text-pursuit-red">*</span>
        </label>
        <input
          type="text"
          placeholder="First Name"
          className="h-10 px-2 mt-2 mb-4 shadow lg:w-2/3 2xl:w-1/2"
          value={caseState.firstName || undefined}
          onChange={event => setInput('firstName', event.target.value)}
          disabled={disableNameEdit}
        />

        <label className="text-pursuit-gray">Middle Name</label>
        <input
          type="text"
          placeholder="Middle Name"
          className="h-10 px-2 mt-2 mb-4 shadow lg:w-2/3 2xl:w-1/2"
          value={caseState.middleName || undefined}
          onChange={event => setInput('middleName', event.target.value)}
          disabled={disableNameEdit}
        />

        <label className="text-pursuit-gray">
          Last Name<span className="ml-1 text-pursuit-red">*</span>
        </label>
        <input
          type="text"
          placeholder="Last Name"
          className="h-10 px-2 mt-2 mb-4 shadow lg:w-2/3 2xl:w-1/2"
          value={caseState.lastName || undefined}
          onChange={event => setInput('lastName', event.target.value)}
          disabled={disableNameEdit}
        />

        <label className="text-pursuit-gray">
          Age<span className="ml-1 text-pursuit-red">*</span>
        </label>

        <input
          type="text"
          placeholder="Age"
          className="h-10 px-2 mt-2 mb-4 shadow lg:w-56"
          value={caseState.age || undefined}
          onChange={event => setInput('age', event.target.value)}
        />

        {/* 
        <div className="flex flex-col lg:flex-row lg:mt-1 lg:mb-2">
          <p>* Location information is now added from a Escort Ad source.</p>
        </div>

        <div className="flex flex-col lg:flex-row lg:mt-1 lg:mb-2">
          <div className="flex flex-col">
            <label className="text-pursuit-gray">
              City<span className="ml-1 text-pursuit-red">*</span>
            </label>

            <input
              type="text"
              placeholder="City"
              className="h-10 px-2 mt-2 mb-4 shadow lg:mr-8 lg:w-56"
              value={caseState.city}
              disabled
              onChange={event => setInput('city', event.target.value)}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-pursuit-gray">
              State<span className="ml-1 text-pursuit-red">*</span>
            </label>

            <select
              name="State"
              disabled
              className={`custom-select pl-2 mt-2 mb-4 h-10 lg:w-40 shadow bg-white ${
                !caseState.state ? 'text-gray-200' : 'text-pursuit-gray'
              }`}
              onChange={event => setInput('state', event.target.value)}
              value={caseState.state || ''}
              placeholder="Select"
            >
              <option key="default-state" value="" disabled="disabled">
                Select
              </option>

              {states.map((state, i) => (
                <option key={i}>{state.name}</option>
              ))}
            </select>
          </div>
        </div>
 */}
        <label className="text-pursuit-gray">Additional Details and Comments</label>

        <textarea
          placeholder="Notes or comments"
          className="h-24 pl-1 mt-1 mb-2 shadow"
          value={caseState.details || undefined}
          onChange={event => setInput('details', event.target.value)}
        />
      </div>
    </>
  );
};

export default PersonalInfoForm;
