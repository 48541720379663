import React, { useState, useEffect } from 'react';
import { ESCORT_AD, CaseStatus } from '../../constants';
import ConfirmationStep from '../ConfirmationStep';
import PointBreakdown from '../PointBreakdown';
import InvestigatorNotes from '../InvestigatorNotes';

const ConfirmationForm = ({ caseState, reviewing }) => {
  const [awardClassificationOption, setAwardClassificationOption] = useState('');

  useEffect(() => {
    setAwardClassificationOption(caseState.awardClassificationOption);
  }, [caseState.awardClassificationOption]);

  const renderSteps = () => {
    const awards = caseState.awardedPoints ? [...caseState.awardedPoints] : [];

    const steps = [
      // {
      //   title: 'Escort Ad',
      //   body: caseState.escortAd,
      //   id: ESCORT_AD,
      //   award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      // },
    ];

    const escortAdData = [
      caseState.escortAd.url,
      ...caseState.escortAd.phones.map(({ phone }) => phone),
      caseState.escortAd.description,
    ];
    const escortAdEmpty = escortAdData.filter(Boolean).length === 0;
    console.log({
      emptyAdData: escortAdData,
      escortAdEmpty,
    });
    if (!escortAdEmpty) {
      steps.push({
        title: 'Escort Ad',
        body: caseState.escortAd,
        id: ESCORT_AD,
        award: awards.find(x => x.stepId === ESCORT_AD)?.awardType || null,
      });
    }

    caseState.webSources.map(source => {
      steps.push({
        title: source.website ? source.website : '[no source type selected]',
        body: source,
        id: source.id,
        award: awards.find(x => x.stepId === source.id)?.awardType || null,
      });

      return null;
    });

    return steps.map((data, idx) => (
      <ConfirmationStep
        key={`Source_${idx}`}
        data={data}
        index={idx}
        numSteps={steps.length}
        isLast={idx === steps.length - 1}
        expandAll
        hideExpand
        noSwaps
        feedbackComments={caseState?.feedbackComments || []}
        status={caseState?.status}
        awardClassificationOption={awardClassificationOption}
      />
    ));
  };

  return (
    <>
      {!reviewing && (
        <div className="mb-8">
          {caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted ? (
            <h2 className="text-lg font-bold text-pursuit-gray">Approved Case Details</h2>
          ) : (
            <>
              <h2 className="text-lg font-bold text-pursuit-gray">Final Review and Submit</h2>
              <div className="text-sm text-pursuit-gray">
                <p>
                  Take a moment to make a final review of the steps you took to create this investigation. Please be
                  sure to go back and update anything that looks incorrect. Once you have validated that everything is
                  correct, you can click the submit button at the bottom of the screen to send the case to Guardian
                  Group to review.
                </p>
              </div>
            </>
          )}
        </div>
      )}
      <div className="py-4 mb-4">
        <h2 className="mb-2 text-lg font-bold text-pursuit-gray">Personal Details</h2>
        <ul>
          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Name: </b>
              {`${caseState.firstName || ''} ${caseState.middleName || ''} ${caseState.lastName || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Age: </b>
              {caseState.age}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Location: </b>
              {`${caseState.city || ''}, ${caseState.state || ''}`}
            </p>
          </li>

          <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Comments: </b>
              {caseState.details}
            </p>
          </li>

          {/* <li className="my-1">
            <p>
              <b className="text-pursuit-gray">Investigator Notes: </b>
              {caseState.investigatorNotes}
            </p>
          </li> */}
        </ul>
      </div>

      {(caseState.status === CaseStatus.Approved || caseState.status === CaseStatus.Resubmitted) && (
        <div className="mb-8">
          <PointBreakdown
            expandAll
            noSwaps
            feedbackComments={caseState?.feedbackComments || []}
            status={caseState?.status}
            awardClassificationOption={awardClassificationOption}
            caseRecord={caseState}
          />
        </div>
      )}

      {renderSteps()}

      <InvestigatorNotes
        notes={caseState.investigatorNotes}
        canEdit={false}
        canDelete={false}
        canSort={false}
        // setNotes={note => {
        //   setInput('investigatorNotes', note);
        // }}
      />
    </>
  );
};

export default ConfirmationForm;
