export const websiteList = [
  'CashApp',
  'Date of Birth',
  'Email',
  'Escort Ad',
  'Facebook',
  'Instagram',
  'Kik',
  'Messenger',
  'PayPal',
  'Periscope',
  'Pinterest',
  'Reddit',
  'Snapchat',
  'Sound Cloud',
  'Telegram',
  'TikTok',
  'Tumblr',
  'Twitch',
  'Venmo',
  'Website',
  'WhatsApp',
  'Twitter',
  'YouTube',
  // 'X',
  'Other',
];
const websiteLabelOverrides = {
  Twitter: 'X',
};

export const getWebSourceSiteLabel = field => {
  return websiteLabelOverrides[field] || field;
};

// export const webSourceRequiredFields = ['url', 'description'];
export const webSourceRequiredFields = ['url'];
export const webSourceRequiredFieldsStructured = {
  escortad: ['url', 'adLocation', 'adDate', 'phoneNumber', 'escortSiteTitle'],
};

export const webSourceFields = {
  cashapp: {
    cashtag: '',
    displayName: '',
    profilePictureUrl: '',
    registeredPhoneNumber: '',
    // url: '',

    description: '',
  },
  dateofbirth: {
    dateOfBirth: '',
    description: '',
  },
  email: {
    email: '',
    description: '',
  },
  escortad: {
    escortSiteTitle: '',
    // url: '',
    adLocation: '',
    adDate: '',
    phoneNumber: '',
    phoneType: '',
    description: '',
  },
  facebook: {
    profileName: '',
    userId: '',
    username: '',
    description: '',
  },
  instagram: {
    displayName: '',
    userId: '',
    username: '',
    description: '',
  },
  kik: {
    displayName: '',
    username: '',
    description: '',
  },
  messenger: {
    username: '',
    description: '',
  },

  paypal: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
    description: '',
  },
  periscope: {
    displayName: '',
    username: '',
    description: '',
  },
  pinterest: {
    displayName: '',
    username: '',
    description: '',
  },
  reddit: {
    username: '',
    description: '',
  },
  snapchat: {
    displayName: '',
    username: '',
    description: '',
  },
  soundcloud: {
    displayName: '',
    username: '',
    description: '',
  },
  telegram: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
    description: '',
  },
  tiktok: {
    displayName: '',
    userId: '',
    username: '',
    description: '',
  },
  tumblr: {
    displayName: '',
    username: '',
    description: '',
  },
  twitch: {
    displayName: '',
    username: '',
    description: '',
  },
  twitter: {
    displayName: '',
    username: '',
    description: '',
  },
  venmo: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
    description: '',
  },
  website: {
    displayName: '',
    title: '',
    username: '',
    description: '',
  },
  whatsapp: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
    description: '',
  },
  youtube: {
    displayName: '',
    username: '',
    description: '',
  },
  other: {
    displayName: '',
    title: '',
    userId: '',
    username: '',
    description: '',
  },
};

// Pretty labels to show next to fields
export const webSourceFieldLabels = {
  url: 'URL',
  description: 'Description',
  title: 'Title',
  escortSiteTitle: 'Escort Website',
  username: 'Username',
  displayName: 'Display Name',
  userId: 'User ID',
  dateOfBirth: 'Date of Birth',
  email: 'Email',
  cashtag: 'Cashtag',
  profileName: 'Profile Name',
  profilePictureUrl: 'Profile Picture URL',
  registeredPhoneNumber: 'Registered Phone Number',
  phoneNumber: 'Phone Number',
  phoneType: 'Type',
  adLocation: 'Location [Posted City]',
  adDate: 'Date [Posted Date]',
};

// Hint text to show inside fields
export const webSourceFieldPlaceholders = {
  url: 'Paste URL',
  description: 'Explain how you got to this point and your analysis',
  registeredPhoneNumber: '555-555-5555',
  phoneNumber: '555-555-5555',
};

export const webSourceEscortSiteOptions = [
  {
    label: 'Adult Look',
    value: 'adult-look',
  },
  {
    label: 'Adult Search',
    value: 'adult-search',
  },
  {
    label: 'CallEscort',
    value: 'callescort',
  },
  {
    label: 'Eros',
    value: 'eros',
  },
  {
    label: 'KittyAds',
    value: 'kitty-ads',
  },
  {
    label: 'Megapersonals',
    value: 'megapersonals',
  },
  {
    label: 'PrivateDelights',
    value: 'private-delights',
  },
  // {
  //   label: 'Slixa',
  //   value: 'slixa',
  // },
  {
    label: 'SkipTheGames',
    value: 'skip-the-games',
  },
  // {
  //   label: 'SumoSearch',
  //   value: 'sumosearch',
  // },
  // {
  //   label: 'Switter',
  //   value: 'switter',
  // },
  // {
  //   label: 'TNA Board',
  //   value: 'tna-board',
  // }
  {
    label: 'Tryst',
    value: 'tryst',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const webSourceDescriptionHelper = {
  escortad:
    'If more than one number exists in this unique ad, please place that information here along with any other amplifying content. For example, on SkipTheGames, there may be a number listed in the ad description but a different number behind the Call Me captcha. This is what you’d list here instead of making it a separate source.',
};
export const webSourceTooltips = {
  escortad: {
    // escortSiteTitle: 'website name',
    adLocation:
      'The location that is listed here is specific to the latest escort ad posted city. This is not a location where you believe the person is from or residing. The reason for this being specific to the city posted in, allows for Guardian Group to deliver it to the correct law enforcement jurisdiction for appropriate action.',
    adDate: `The date required here is the date the escort ad was posted online. 90% of the time this is obvious on the escort ad. However, there are sites that it's a bit more obscure or hidden on purpose. If you select an ad from one of the sites where it's not obvious, select today's date instead so you may still submit the lead. Guardian Group will determine the validity of the date.`,
    description:
      'If more than one number exists in this unique ad, please place that information here along with any other amplifying content. For example, on SkipTheGames, there may be a number listed in the ad description but a different number behind the Call Me captcha. This is what you’d list here instead of making it a separate source.',
  },
};
