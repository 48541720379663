import { uniqueKey } from 'highcharts';
import { CaseStatus } from '../../constants';
import { webSourceFields, websiteList } from './webSourceFields';

export const initialPersonalInfoState = {
  firstName: '',
  lastName: '',
  middleName: '',
  age: '',
  city: '',
  state: '',
  details: '',
};

export const initialEscortAdState = {
  url: '',
  phones: [
    {
      type: 'Cellular',
      number: '',
      id: 'initial',
    },
  ],
};

export const initialWebSourceState = {
  website: 'CashApp',
  // uniqueFields: { ...webSourceFields[websiteList[0].toLowerCase()] },
  uniqueFields: {
    cashtag: '',
    displayName: '',
    profilePictureUrl: '',
    registeredPhoneNumber: '',
  },
  url: '',
  description: '',
};

const escortAdSourceFields = webSourceFields.escortad;
// delete escortAdSourceFields.description;
export const initialEscortAdSourceState = {
  website: 'Escort Ad',
  // uniqueFields: webSourceFields.escortad,
  uniqueFields: escortAdSourceFields,
  url: '',
  description: '',
};

export const initialCaseState = {
  caseNumber: '',
  firstName: '',
  lastName: '',
  middleName: '',
  age: '',
  city: '',
  state: '',
  details: '',
  escortAd: initialEscortAdState,
  // webSources: [],
  webSources: [initialEscortAdSourceState],
  status: CaseStatus.Active,
};
