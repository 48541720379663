import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Button from '../Button';
import Feedback from '../Feedback';
import PopUpConfirmation from '../PopupConfirmation';
import {
  webSourceFields,
  webSourceRequiredFields,
  websiteList,
  webSourceEscortSiteOptions,
  webSourceDescriptionHelper,
  webSourceTooltips,
  webSourceRequiredFieldsStructured,
  getWebSourceSiteLabel,
} from '../../helpers/caseForms/webSourceFields';
import {
  readableFormat,
  usesOldEscortAdFormat,
  webSourceFieldLabel,
  webSourceFieldPlaceholder,
} from '../../helpers/caseForms/utilHelpers';
import { CaseStatus } from '../../constants';
import { formatPhoneNumber } from '../PhoneField/phoneFieldHelper';
// import { formatPhoneNumber } from '../../helpers/caseForms/inputFormatters';
import styles from './styles.module.css';
import locations from './locations.json';
import Select from 'react-select';
import ToolTip from '../ToolTip';
import * as Icons from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const WebSourceForm = ({
  caseState,
  setCaseState: setCaseStateFunc,
  stepNumber,
  webSourceIndex,
  setGlobalIndex,
  saveAndAddButton,
  reviewing,
  removeLabel = 'DELETE SOURCE',
  setFormNumber,
  staffSource,
}) => {
  // const sourceKey = staffSource ? 'staffSources' : 'webSources';
  const [sourceKey] = useState(staffSource ? 'staffSources' : 'webSources');
  const [showDelete, setShowDelete] = useState(false);
  // const [webSourceIndex] = useState(webSourceIndexProp);
  const setCaseState = useCallback(
    data => {
      // Remove url from unique fields
      // if (data[sourceKey][webSourceIndex].uniqueFields) {
      //   delete data[sourceKey][webSourceIndex].uniqueFields.url;
      // }
      setCaseStateFunc(data);
    },
    [setCaseStateFunc]
  );
  const removeActionLower = removeLabel.toLowerCase();
  const removeActionTitleCase = removeActionLower.charAt(0).toUpperCase() + removeActionLower.slice(1);

  const removeTitle = `${removeActionTitleCase} Step`;
  const removeConfirmation = `Are you sure you want to ${removeActionLower} this step?`;

  // console.log({ ...caseState });

  // const [sourcedWebsite, setSourcedWebsite] = useState(caseState[sourceKey][webSourceIndex]?.website);

  // useEffect(() => {
  //   setSourcedWebsite(caseState[sourceKey][webSourceIndex]?.website);
  // }, [caseState, sourceKey, webSourceIndex]);

  useEffect(() => {
    // Check if source is a object, if not return;
    if (typeof caseState[sourceKey][webSourceIndex] !== 'object') {
      return;
    }

    const savedWebsite = caseState[sourceKey][webSourceIndex]?.website;
    const savedUniqueFields = caseState[sourceKey][webSourceIndex]?.uniqueFields;

    let hasSavedUniqueFields = false;

    if (savedUniqueFields) {
      hasSavedUniqueFields = !!Object.keys(savedUniqueFields).length;
    }

    if (savedWebsite) {
      const fields = webSourceFields[savedWebsite.replace(/ /g, '').toLowerCase()];
      // const fields = Object.keys(webSourceFields[savedWebsite.replace(/ /g, '').toLowerCase()])
      //   .filter(field => !webSourceRequiredFields.includes(field))
      //   .reduce((obj, key) => {
      //     obj[key] = null;

      //     return obj;
      //   }, {});

      // console.log('Fields', { fields });
      if (!hasSavedUniqueFields) {
        const updatedWebSources = JSON.parse(JSON.stringify(caseState[sourceKey]));

        if (fields) {
          updatedWebSources[webSourceIndex].uniqueFields = JSON.parse(JSON.stringify(fields));
        }
        // console.log('website update', caseState);
        setCaseState({
          ...caseState,
          [sourceKey]: updatedWebSources,
        });
      } else {
        // console.log({ fields });
        const updatedFields = { ...fields };

        // console.log(1, JSON.stringify(updatedFields));

        // for each field in savedUniqueFields check if it is in fields
        for (const savedFieldKey of Object.keys(savedUniqueFields)) {
          // console.log({ updatedFields, savedFieldKey });
          if (Object.hasOwn(updatedFields, savedFieldKey)) {
            updatedFields[savedFieldKey] = savedUniqueFields[savedFieldKey];
          }
        }

        // console.log(2, JSON.stringify(updatedFields));

        const expectedFields = Object.keys(fields);

        for (const expectedField of expectedFields) {
          if (!(expectedField in savedUniqueFields)) {
            savedUniqueFields[expectedField] = fields[expectedField];
          }
        }

        const savedFieldKeys = Object.keys(savedUniqueFields);

        for (const savedFieldKey of savedFieldKeys) {
          if (!(savedFieldKey in fields)) {
            delete savedUniqueFields[savedFieldKey];
          }
        }

        const updatedWebSources = JSON.parse(JSON.stringify(caseState[sourceKey]));

        // Remove the required fields from the updatedFields if present
        for (const requiredField of webSourceRequiredFields) {
          // const hasProp = Object.hasOwn({...updatedFields}, requiredField);

          // console.log({ requiredField, updatedFields, hasProp, hasProp2: updatedFields.hasOwnProperty(requiredField) });
          if (Object.hasOwn(updatedFields, requiredField)) {
            // console.log('delete', requiredField);
            // delete updatedFields[requiredField];
          }
        }
        const uniqueFieldsToSave = Object.keys(updatedFields)
          .filter(field => !webSourceRequiredFields.includes(field))
          .filter(field => field !== 'description')
          .reduce((obj, key) => {
            obj[key] = updatedFields[key];

            return obj;
          }, {});

        updatedWebSources[webSourceIndex] = {
          ...updatedWebSources[webSourceIndex],
          // uniqueFields: savedUniqueFields,
          // uniqueFields: updatedFields,
          uniqueFields: uniqueFieldsToSave,
        };

        // console.log(JSON.stringify(updatedFields));
        // console.log(
        //   'website update 2',
        //   caseState,
        //   JSON.stringify({ savedUniqueFields, updatedFields, uniqueFieldsToSave })
        // );
        setCaseState({
          ...caseState,
          [sourceKey]: updatedWebSources,
        });
      }
    } else if (!savedWebsite) {
      const defaultWebsite = websiteList[0];

      const fields = webSourceFields[websiteList[0].toLowerCase()];

      const updatedWebSources = JSON.parse(JSON.stringify(caseState[sourceKey]));

      updatedWebSources[webSourceIndex].website = defaultWebsite;

      if (fields) {
        updatedWebSources[webSourceIndex].uniqueFields = JSON.parse(JSON.stringify(fields));
      }

      // console.log('website update 3', caseState);
      setCaseState({
        ...caseState,
        [sourceKey]: updatedWebSources,
      });
    }
  }, [caseState[sourceKey][webSourceIndex]?.website]);
  // }, [caseState, setCaseState, sourceKey, webSourceIndex]);

  function setInput(key, value) {
    const updatedWebSources = JSON.parse(JSON.stringify(caseState[sourceKey]));
    const updatedValues = { ...updatedWebSources[webSourceIndex], [key]: value };

    console.log({ key, value, webSourceIndex, sourceKey, updatedWebSources, updatedValues });
    // updatedWebSources[webSourceIndex][key] = value;
    updatedWebSources[webSourceIndex] = updatedValues;

    setCaseState({
      ...caseState,
      [sourceKey]: updatedWebSources,
    });
  }

  function setUniqueInput(field, value) {
    // console.log('setUniqueInput', field, value);
    const updatedWebSources = JSON.parse(JSON.stringify(caseState[sourceKey]));
    const webSource = { ...updatedWebSources[webSourceIndex] };
    const { uniqueFields } = webSource;
    const updatedUniqueValues = { ...uniqueFields, [field]: value };
    const updatedWebSource = { ...webSource, uniqueFields: updatedUniqueValues };

    console.log({ field, value, webSourceIndex, sourceKey, updatedWebSources, updatedUniqueValues, updatedWebSource });

    if (
      webSource.website === 'Escort Ad' &&
      webSourceIndex === 0 &&
      field === 'adLocation' &&
      !usesOldEscortAdFormat(caseState)
    ) {
      const [city, state] = value.split(', ');
      if (city) caseState.city = city;
      console.log({ city, state });
      if (state) caseState.state = state;
    }

    // updatedWebSources[webSourceIndex].uniqueFields[field] = value;
    updatedWebSources[webSourceIndex] = updatedWebSource;

    setCaseState({
      ...caseState,
      [sourceKey]: updatedWebSources,
    });
  }

  const deleteSource = useCallback(() => {
    // const sourceKey = staffSource ? 'staffSources' : 'webSources';
    // TODO: Need to delete points if awarded
    // const webSourceCount = caseState.webSources.length;
    const webSourceCount = caseState[sourceKey].length;

    let newGlobalIndex = 0;

    const updatedWebSources = JSON.parse(JSON.stringify(caseState[sourceKey]));

    updatedWebSources.splice(webSourceIndex, 1);
    const newAwardedPoints = (caseState.awardedPoints || []).filter(
      x =>
        x.stepId === 'ESCORT_AD' ||
        updatedWebSources.map(x => x.id).includes(x.stepId) ||
        (caseState.staffSources || []).map(x => x.id).includes(x.stepId)
    );

    const newCaseState = {
      ...caseState,
      [sourceKey]: [...updatedWebSources],
      awardedPoints: [...newAwardedPoints],
    };

    console.log('newCaseState', newCaseState);
    setCaseState(newCaseState);
    setShowDelete(false);

    if (caseState?.status === CaseStatus.Approved) {
      setFormNumber(2);
      setGlobalIndex(1);
    } else {
      if (webSourceCount > 1) {
        newGlobalIndex = webSourceIndex;
      }
      setGlobalIndex(newGlobalIndex);
    }
  }, [caseState, webSourceIndex]);

  // const selectedSource = caseState[sourceKey][webSourceIndex] || { id: null };
  // Default to a empty escort ad
  // const emptyEscortAd = { id: null, uniqueFields: webSourceFields['escortad'] };
  const emptyEscortAd = {
    id: uuidv4(),
    description: '',
    url: '',
    website: 'Escort Ad',
    uniqueFields: webSourceFields['escortad'],
  };
  const selectedSource = caseState[sourceKey][webSourceIndex] || emptyEscortAd;

  // URL and Description are required
  // Add the required fields to the array and then after looping the fields check do see if displayed
  // const requiredFields = [
  //   {
  //     key: 'url',
  //     label: 'URL',
  //     element: (

  //     )

  //   }
  // ];

  // console.log({
  //   selectedSource,
  //   // entries: Object.entries(selectedSource?.uniqueFields),
  // });
  const savedWebsite2 = caseState[sourceKey][webSourceIndex]?.website || 'Escort Ad';
  const webSourceKey = savedWebsite2.replace(/ /g, '').toLowerCase();
  const expectedSourceFields = savedWebsite2 ? webSourceFields[savedWebsite2.replace(/ /g, '').toLowerCase()] : {};

  const fieldsToDisplay = webSourceRequiredFields.reduce(
    (acc, field) => {
      // const fieldsToDisplay = [].reduce((acc, field) => {
      if (!Object.hasOwn(acc, field)) {
        // console.log('Adding field', field, 'to uniqueFields');

        // acc[field] = '';
        // Instead add the field to the beginning of the object
        acc = { [field]: '', ...acc };
        // return Object.assign({ [field]: '' }, acc);
      }

      return acc;
    },
    // { ...selectedSource.uniqueFields }
    { ...expectedSourceFields }
  );

  // console.log({ webSourceRequiredFields, expectedSourceFields, fieldsToDisplay });
  // const fieldsToDisplay = expectedSourceFields.reduce(
  //   (acc, field) => {
  //     // const fieldsToDisplay = [].reduce((acc, field) => {
  //     if (!Object.hasOwn(acc, field)) {
  //       // console.log('Adding field', field, 'to uniqueFields');
  //       acc[field] = '';
  //     }

  //     return acc;
  //   },
  //   // { ...selectedSource.uniqueFields }
  //   { ...webSourceRequiredFields }
  // );

  // const fields = updatedWebSources[webSourceIndex].uniqueFields
  // console.log({ selectedSource, expectedFields });
  const descriptionKey = selectedSource?.website && selectedSource?.website.replace(' ', '').toLowerCase();
  // let descriptionHelper = null;
  // if (webSourceDescriptionHelper[descriptionKey]) {
  //   const descriptionHelperText = webSourceDescriptionHelper[descriptionKey];
  //   descriptionHelper = (
  //     <span title={descriptionHelperText}>
  //       {/* <Icons.FaInfoCircle size={16} className="inline" /> */}
  //       <ToolTip
  //         tooltipText={descriptionHelperText}
  //         title="Details"
  //         className="text-pursuit-gray inline"
  //       />
  //     </span>
  //   );
  // }
  const descriptionHelper = webSourceDescriptionHelper[descriptionKey] ? (
    <span title={webSourceDescriptionHelper[descriptionKey]}>
      <ToolTip
        tooltipText={webSourceDescriptionHelper[descriptionKey]}
        title="Tip"
        className="text-pursuit-gray inline"
      />
    </span>
  ) : null;

  const uniqueFields =
    // selectedSource?.uniqueFields &&
    // Object.entries(selectedSource.uniqueFields).map(([field], i) => {

    fieldsToDisplay &&
    Object.entries(fieldsToDisplay).map(([field], i) => {
      // expectedFields &&
      // Object.entries(expectedFields).map(([field], i) => {
      const formattedField = webSourceFieldLabel(field);
      const isRequired =
        webSourceRequiredFields.includes(field) ||
        (webSourceRequiredFieldsStructured[webSourceKey] &&
          webSourceRequiredFieldsStructured[webSourceKey].includes(field));

      // let fieldValue = isRequired /* typeof selectedSource[field] !== 'undefined'*/
      //   ? selectedSource?.[field] || ''
      //   : selectedSource.uniqueFields[field] || '';
      let fieldValue =
        field === 'description' || field === 'url'
          ? selectedSource?.[field] || ''
          : selectedSource?.uniqueFields?.[field] || '';

      // const updateValue = isRequired ? value => setInput(field, value) : value => setUniqueInput(field, value);
      const updateValue =
        field === 'description' || field === 'url'
          ? value => setInput(field, value)
          : value => setUniqueInput(field, value);

      let onChange = e => {
        updateValue(e.target.value);
      };

      const placeholder = webSourceFieldPlaceholder(field);

      // console.log('formattedField', formattedField);
      // console.log({ field, i, selectedSource, uniqueFields: selectedSource.uniqueFields });
      let ElementType = 'input';
      let fieldType = 'text';
      let classes = `${styles.sourceInput} h-10 px-2 mt-2 mb-4 shadow lg:w-2/3 2xl:w-1/2`;

      let pattern = null;
      let options = null;
      let elementAttributes = {};

      // Adjust field type for special fields
      switch (field) {
        case 'email':
          fieldType = 'email';
          break;
        case 'url':
        case 'profilePictureUrl':
          fieldType = 'url';
          // pattern = 'https?://.+';
          break;
        case 'phoneNumber':
        case 'registeredPhoneNumber':
          fieldType = 'tel';
          pattern = '[0-9]{3}-[0-9]{3}-[0-9]{4}';
          fieldValue = formatPhoneNumber(fieldValue);
          onChange = e => {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);

            updateValue(formattedPhoneNumber);
          };
          break;
        case 'dateOfBirth':
        case 'adDate':
          // So lame to set validation rules in code like this
          const today = new Date();
          const lastYear = new Date(today);
          // tomorrow.setDate(tomorrow.getDate() + 1);
          // lastYear.setFullYear(today.getFullYear() - 1);
          elementAttributes['max'] = today.toISOString().split('T')[0];
        // elementAttributes['min'] = lastYear.toISOString().split('T')[0];
        case 'date':
          fieldType = 'date';
          break;
        case 'description':
          ElementType = 'textarea';
          classes = `${styles.sourceInput} h-24 w-full px-2 mt-2 mb-4 shadow`;
          break;
        case 'escortSiteTitle':
          ElementType = 'select';
          const selectOptions = webSourceEscortSiteOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ));
          options = (
            <>
              <option selected value>
                -- Select an option --
              </option>
              {selectOptions}
            </>
          );
          // selectOptions = ['Escort Site Title', 'Escort Site Title 2'];
          break;
        case 'phoneType':
          ElementType = 'select';
          const phoneOptions = ['Cellular', 'VOIP'].map((option, i) => (
            <option key={i} value={option} selected={i === 0}>
              {option}
            </option>
          ));
          options = (
            <>
              <option selected value>
                -- Select an option --
              </option>
              {phoneOptions}
            </>
          );
          // options += (
          //   <option hidden disabled selected value>
          //     {' '}
          //     -- select an option --{' '}
          //   </option>
          // );
          break;
        case 'adLocation':
        case 'location':
          // ElementType = 'select';
          // Group locations by state with each city as a child option
          const locationStates = locations.reduce((acc, location) => {
            // console.log({ location });
            try {
              const { state, city } = location;
              // Lowercase the state
              const safeState = state.toLowerCase();
              if (!Array.isArray(acc[safeState])) {
                acc[safeState] = [];
              }
              acc[safeState].push(location);
            } catch (error) {
              console.log({ error });
            }
            return acc;
            // acc[location.state].push(location.city);
          }, {});
          // console.log({ locationStates });
          // options = Object.keys(locationStates).map((state, i) => (
          //   <optgroup
          //     key={i}
          //     label={state.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())}>
          //     {locationStates[state].map((location, j) => (
          //       <option key={j} value={`${location.state}:${location.city}`}>
          //         {location.city}
          //       </option>
          //     ))}
          //   </optgroup>
          // ));
          return (
            <span className={styles.sourceField} key={field + i + selectedSource.id + selectedSource.website}>
              <span
                className={`${styles.label} ${styles.validity} text-pursuit-gray`}
                // title={webSourceTooltips[savedWebsite2.replace(/ /g, '').toLowerCase()][field]}
                title={webSourceTooltips[webSourceKey] && webSourceTooltips[webSourceKey][field]}>
                {formattedField}{' '}
                {webSourceTooltips[webSourceKey] && webSourceTooltips[webSourceKey][field] && (
                  <ToolTip
                    tooltipText={webSourceTooltips[webSourceKey][field]}
                    title="Information"
                    className="text-pursuit-gray inline"
                  />
                )}{' '}
                {isRequired && <span className="ml-1 text-pursuit-red">* </span>}
              </span>
              <Select
                className="mb-4 lg:w-2/3 2xl:w-1/2"
                // onChange={onchange}
                defaultInputValue={fieldValue}
                onChange={selectedOption => {
                  console.log({ selectedOption });
                  // updateValue(selectedOption.value);
                  onChange({ target: { value: selectedOption.value } });
                }}
                options={
                  //Object.keys(locationStates).map((state, i) => {
                  // return {
                  //   // label: state.replace(
                  //   //   /\w\S*/g,
                  //   //   text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
                  //   // ),
                  //   options: locationStates[state].map((location, j) => {
                  //     return {
                  //       label: location.city,
                  //       value: `${location.state}:${location.city}`,
                  //     };
                  //   }),
                  // };
                  // return locationStates[state].map((location, j) => {
                  //   return {
                  //     label: `${location.city}, ${location.state}`,
                  //     value: `${location.state}:${location.city}`,
                  //   };
                  // });
                  locations.map((location, j) => {
                    return {
                      label: `${location.city}, ${location.state}`,
                      value: `${location.city}, ${location.state}`,
                      // value: `${location.state}:${location.city}`,
                    };
                  })
                  //}).flatMap(x => x)}
                }
              />
            </span>
          );

        // options = locationStates.map((state, i) => (
        //   <optgroup key={i} label={location.state}>
        //     {location.cities.map((city, j) => (
        //       <option key={j} value={`${location.state}:${city}`}>
        //         {city}
        //       </option>
        //     ))}
        //   </optgroup>
        // ));

        // options = locations.map((location, i) => (
        //   <option key={i} value={`${location.state}:${location.city}`}>
        //     {/* {location.state}, {location.city} */}
        //     {location.city}, {location.state}
        //   </option>
        // ));
        // options = [
        //   <option key="location" value="">
        //     Location
        //   </option>,
        //   <option key="location1" value="location1">
        //     Location 1
        //   </option>,
        //   <option key="location2" value="location2">
        //     Location 2
        //   </option>,
        // ];
        default:
        // Do nothing
      }

      return (
        <span className={styles.sourceField} key={field + i + selectedSource.id + selectedSource.website}>
          {/* remove the spaces to get the key */}
          <span
            className={`${styles.label} ${styles.validity} text-pursuit-gray`}
            title={webSourceTooltips[webSourceKey] && webSourceTooltips[webSourceKey][field]}>
            {formattedField}{' '}
            {webSourceTooltips[webSourceKey] && webSourceTooltips[webSourceKey][field] && (
              <ToolTip
                tooltipText={webSourceTooltips[webSourceKey][field]}
                title="Information"
                className="text-pursuit-gray inline"
              />
            )}{' '}
            {isRequired && <span className="ml-1 text-pursuit-red">* </span>}
            {/* {field === 'description' && descriptionHelper} */}
          </span>
          {/* <ToolTip
            tooltipText={webSourceDescriptionHelper[descriptionKey]}
            title="Details"
            className="text-pursuit-gray inline"
          /> */}
          <ElementType
            type={fieldType}
            placeholder={placeholder}
            className={classes}
            // value={selectedSource.uniqueFields[field] || ''}
            value={fieldValue}
            // No required attribute for now
            required={isRequired}
            pattern={pattern}
            onChange={onChange}
            // onChange={event => {
            //   setUniqueInput(field, event.target.value);
            // }}
            key={`unique-input-${field}-${i}-${selectedSource.id}-${selectedSource.website}`}
            children={options}
            {...elementAttributes}
          />
          {(isRequired || (pattern && fieldValue)) && <span className="validity"></span>}
          {/* <span class="validity"></span> */}
        </span>
      );
    });

  return (
    <>
      {showDelete && (
        <PopUpConfirmation
          title={removeTitle}
          content={removeConfirmation}
          onConfirm={() => {
            deleteSource();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
          // confirmText={removeLabel}
          confirmText="CONFIRM"
          destructive
          className="w-11/12 lg:w-auto"
        />
      )}
      <div className="mb-8">
        {reviewing ? (
          <h2 className="text-lg font-bold text-pursuit-gray">Source {stepNumber}</h2>
        ) : (
          <h2 className="text-lg font-bold text-pursuit-gray">Add Source {stepNumber}</h2>
        )}
        <div className="text-sm text-pursuit-gray">
          {!reviewing && stepNumber === 1 && (
            <p>
              Source 1 will always be an escort ad starting point. Therefore all Source Types in the dropdown are not
              selectable when viewing or adding Source 1.
            </p>
          )}
          {!reviewing && stepNumber !== 1 && (
            <p>
              Fill out as much info as you can about this source. You can add additional sources by clicking the 'Save
              and Add New Source' button. Once you have added all of your sources, proceed to the next page to review
              your details and submit your case for review.
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <label className="text-pursuit-gray">Source Type</label>

        <select
          id="source-type"
          name="Source Type"
          className="h-10 pl-2 mt-2 mb-4 bg-white shadow custom-select lg:w-64"
          value={selectedSource?.website}
          onChange={event => {
            setInput('website', event.target.value);
          }}>
          {websiteList.map((website, i) => (
            // <option key={i} {website}</option>
            <option key={i} value={website} disabled={stepNumber <= 1 && website !== 'Escort Ad'}>
              {getWebSourceSiteLabel(website)}
            </option>
          ))}
        </select>

        {uniqueFields}

        {/* <label className="text-pursuit-gray">URL</label>
        <input
          type="url"
          placeholder="Paste URL"
          className="h-10 px-2 mt-2 mb-4 shadow lg:w-2/3 2xl:w-1/2"
          value={selectedSource?.url || ''}
          onChange={event => {
            setInput('url', event.target.value);
          }}
        />

        <label className="text-pursuit-gray">Description</label>
        <textarea
          placeholder="Explain how you got to this point and your analysis"
          className="h-24 px-2 mt-2 mb-4 shadow"
          value={selectedSource?.description || ''}
          onChange={event => {
            setInput('description', event.target.value);
          }}
        /> */}

        <div className="flex flex-col mt-4 lg:flex-row-reverse">
          {saveAndAddButton}
          {!reviewing && (
            <Button solidRed padding="lg:mr-8" className="mt-4 lg:w-88 lg:mt-0" onClick={() => setShowDelete(true)}>
              {/* DELETE SOURCE */}
              {removeLabel}
            </Button>
          )}
        </div>
      </div>

      {selectedSource && (
        <Feedback comments={caseState?.feedbackComments || []} stepId={selectedSource.id} status={caseState?.status} />
      )}
    </>
  );
};

export default WebSourceForm;
