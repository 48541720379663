import React, { useEffect, useState, useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { snakeCase } from 'change-case';
import {
  Container,
  HeaderText,
  DataGrid,
  Button,
  StatusPill,
  PopUpConfirmation,
  DropDown,
  ToolTip,
} from '../../../components';
import { listOwnedCases } from '../../../generated/graphql/queries';
import { deleteCase } from '../../../generated/graphql/mutations';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';
import { CaseStatus } from '../../../constants';
import Trash from '../../../assets/Images/trash.svg';

const CaseStatusOptions = [
  { value: CaseStatus.Approved, label: 'Approved' },
  { value: CaseStatus.Active, label: 'Active' },
  { value: CaseStatus.Submitted, label: 'Submitted' },
  { value: CaseStatus.Resubmitted, label: 'Resubmitted' },
  { value: CaseStatus.Returned, label: 'Returned' },
];

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const ActiveCases = () => {
  const query = useQuery();
  const passedStatus = query.get('status')?.toUpperCase();
  let initialFilterStatus = null;

  if (Object.values(CaseStatus).indexOf(passedStatus) > -1) {
    initialFilterStatus = passedStatus;
  }

  const [, setAlert] = useContext(NotificationContext);

  const [nextCaseToken, setNextCaseToken] = useState();

  const [cases, setCases] = useState([]);
  const [casesLoading, setCasesLoading] = useState(true);

  const [status, setStatus] = useState(initialFilterStatus || null);

  const [filters, setFilters] = useState({
    status: initialFilterStatus || null,
    searchTerm: '',
  });

  const [sort, setSort] = useState({
    fieldName: 'createdAt',
    direction: 'DESC',
  });

  const [filtersOnLastSearch, setFiltersOnLastSearch] = useState(null);
  const [sortOnLastSearch, setSortOnLastSearch] = useState({
    fieldName: 'createdAt',
    direction: 'DESC',
  });

  const [search, setSearch] = useState('');

  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    fetchCases();
  }, [sort]);

  useEffect(() => {
    fetchCases();
  }, [filters]);

  const fetchCases = async () => {
    setCasesLoading(true);

    try {
      let nextTokenToUse = nextCaseToken;
      let oldCases = nextTokenToUse ? [...cases] : [];

      if (
        !filtersOnLastSearch ||
        filtersOnLastSearch.status !== filters.status ||
        filtersOnLastSearch.searchTerm !== filters.searchTerm ||
        sortOnLastSearch.fieldName !== sort.fieldName ||
        sortOnLastSearch.direction !== sort.direction
      ) {
        setNextCaseToken(null);

        nextTokenToUse = null;
        oldCases = [];
      }

      setSortOnLastSearch(sort);
      setFiltersOnLastSearch(filters);

      const params = {
        status: filters.status,
        assignedTo: filters.assignedTo,
        searchTerm: filters.searchTerm,
        nextToken: nextTokenToUse,
        sortField: sort.fieldName ? snakeCase(sort.fieldName).toUpperCase() : '',
        sortOrder: sort.direction,
        limit: 100,
      };
      const response = await API.graphql(graphqlOperation(listOwnedCases.replace('assignedToName\n', ''), params));

      const result = response.data.listOwnedCases;

      setNextCaseToken(result.nextToken);
      setCases([...oldCases, ...result.items]);
    } catch (error) {
      console.error('Error Loading cases: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading active cases',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setCasesLoading(false);
  };

  const deleteRecord = async id => {
    try {
      setDeleting(true);

      const updatedCases = [...cases];

      const indexOfRecord = updatedCases.findIndex(x => x.id === id);
      // Volunteers can't access assignedToName so we need to remove it
      const isAdmin = false;
      const query = isAdmin ? deleteCase : deleteCase.replace('assignedToName\n', '');
      await API.graphql(graphqlOperation(query, { id }));

      updatedCases.splice(indexOfRecord, 1);

      setCases(updatedCases);
    } catch (error) {
      console.error('Error deleting record: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'There was an error removing the case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setRecordToDelete(null);
    setDeleting(false);
  };

  const renderDelete = record => {
    if (record.status !== CaseStatus.Active && record.status !== CaseStatus.Returned) {
      return null;
    }

    return (
      <div className="flex flex-row items-center justify-between w-full focus:outline-none lg:w-auto ">
        <span className="font-semibold lg:hidden">Delete Case:</span>

        <button type="button" onClick={() => setRecordToDelete(record)} className="focus:outline-none">
          <img src={Trash} alt="Trash" className="object-contain w-5" />
        </button>
      </div>
    );
  };

  const renderStatus = ({ status: recordStatus }) => <StatusPill status={recordStatus} />;

  const renderPoints = ({ totalPoints }) => (
    <div
      className={`${getAwardColor(
        totalPoints
      )} h-6 w-8 rounded-full flex font-bold justify-center items-center p-0 mr-4`}>
      {totalPoints?.pointsAwarded || ''}
    </div>
  );

  const getAwardColor = totalPoints => {
    // if points aren't awared yet don't show
    if (!totalPoints) {
      return 'bg-white text-white';
    }

    if (!totalPoints.isFullPoints) {
      return 'bg-pursuit-amber text-white';
    }

    return 'bg-green-500 text-white';
  };

  const getCreatedDate = item => (item.createdAt ? DateTime.fromISO(item.createdAt).toLocaleString() : '-');

  return (
    <>
      {recordToDelete ? (
        <PopUpConfirmation
          title="Delete Record"
          content={`Are you sure you want to delete ${recordToDelete?.caseNumber || 'this record'}?`}
          onConfirm={() => {
            deleteRecord(recordToDelete.id);
          }}
          onCancel={() => {
            setRecordToDelete(null);
          }}
          confirmLoading={deleting}
          confirmText="DELETE"
          confirmLoadingText="DELETING"
          destructive
          className="w-11/12 lg:w-auto"
        />
      ) : null}

      <Container height="lg:h-152" width="lg:mx-8" padding="p-4" margin="m-3 lg:m-auto">
        <div className="flex flex-col pt-4 lg:px-4">
          <HeaderText className="mb-4 text-2xl text-left lg:text-4xl">My Cases</HeaderText>

          <div className="items-end justify-end mb-10 lg:flex">
            <div className="lg:mr-4">
              <DropDown
                value={CaseStatusOptions.find(x => x.value === status)}
                onChange={option => {
                  setStatus(option ? option.value : null);
                }}
                containerClassName="w-72"
                width="w-72"
                label="Case Status"
                options={CaseStatusOptions}
                placeholder="Status"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row">
                <p className="mb-1 font-light light-primary-blue-text">Search Term</p>

                <ToolTip
                  tooltipText="Find records with the search term included in the Case Number, First Name, or Last Name fields."
                  title="Search Term"
                  className="ml-2 text-pursuit-gray"
                />
              </div>
              <input
                type="text"
                value={search}
                onChange={event => setSearch(event.target.value)}
                placeholder="Search Term"
                className="w-64 h-10 px-4 bg-gray-100 lg:mr-4"
                onKeyDown={event => {
                  const pressedKey = event.key;

                  if (pressedKey === 'Enter') {
                    setFilters({
                      status,
                      searchTerm: search,
                    });
                  }
                }}
              />
            </div>

            <Button
              solidBlue
              className="w-24 px-4"
              onClick={() => {
                setFilters({
                  status,
                  searchTerm: search,
                });
              }}>
              FILTER
            </Button>
          </div>

          <DataGrid
            columns={[
              { title: '', renderFunction: renderDelete, width: '2rem', noLink: true },
              { title: 'Case Number', fieldName: 'caseNumber', sortable: true },
              { title: 'First Name', fieldName: 'firstName', sortable: true },
              { title: 'Last Name', fieldName: 'lastName', sortable: true },
              { title: 'Date Opened', fieldName: 'createdAt', value: getCreatedDate, sortable: true },
              { title: 'Case Status', fieldName: 'status', renderFunction: renderStatus },
              { title: 'Points', fieldName: 'totalPoints', renderFunction: renderPoints },
            ]}
            sort={sort}
            setSort={setSort}
            loading={casesLoading}
            data={cases}
            noRecordsText="No cases have been submitted"
            rowLink={item => `/investigation/${item.id}`}
            loadNextPage={() => fetchCases(filters)}
            hasMore={!!nextCaseToken}
            containerHeight="h-88"
            gridKey="active-cases"
          />
        </div>
      </Container>
    </>
  );
};

export default ActiveCases;
