import { webSourceFieldLabel } from './utilHelpers';
import { webSourceRequiredFields, webSourceRequiredFieldsStructured } from './webSourceFields';

export function personalInfoValidator(setAlert, caseState) {
  const firstNameEmpty = !caseState.firstName;
  const lastNameEmpty = !caseState.lastName;
  const ageEmpty = !caseState.age;
  // TODO: Move to use escort ad location instead of case location
  const cityEmpty = !caseState.city;
  const stateEmpty = !caseState.state;
  // const cityEmpty = false;
  // const stateEmpty = false;

  const requiredFields = [];

  if (firstNameEmpty) {
    requiredFields.push('First name is required');
  }
  if (lastNameEmpty) {
    requiredFields.push('Last name is required');
  }
  if (ageEmpty) {
    requiredFields.push('Age is required');
  }
  if (cityEmpty) {
    requiredFields.push('City is required');
  }
  if (stateEmpty) {
    requiredFields.push('State is required');
  }

  if (requiredFields.length !== 0) {
    const message = `${requiredFields.join(', ')} (Personal Information)`;

    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message,
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !firstNameEmpty && !lastNameEmpty && !ageEmpty && !cityEmpty && !stateEmpty;
}

export function escortAdValidator(setAlert, escortAdState, caseState) {
  if (!escortAdState) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Escort Ad Incomplete',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  let escortAdInformation = {};
  if (caseState) {
    const firstSource = caseState.webSources[0];
    if (firstSource.website === 'Escort Ad') {
      escortAdInformation = firstSource;
    }
  }
  console.log('escortAdInformation', escortAdInformation);

  // console.log('escortAdState', escortAdState);

  let urlEmpty = !escortAdState.url;
  let noPhones =
    !escortAdState.phones || !escortAdState.phones.length || escortAdState.phones.every(obj => !obj.number);
  let invalidPhone = false;
  let uniqueFieldsValid = true;

  if (urlEmpty && escortAdInformation.url) {
    // Check if the URL is empty
    urlEmpty = !escortAdInformation.url;
  }

  if (escortAdState.phones && escortAdState.phones.length) {
    escortAdState.phones.forEach(obj => {
      if (!obj.number || !obj.type) {
        invalidPhone = true;
      } else {
        const phone = obj.number.replace('-', '');

        if (phone.length < 10) {
          invalidPhone = true;
        }
      }
    });
  }
  if (invalidPhone && escortAdInformation?.uniqueFields?.phoneNumber) {
    // Check if the phone number is valid
    noPhones = false;
    const phone = escortAdInformation.uniqueFields.phoneNumber.replaceAll('-', '');
    console.log('phone', phone);
    if (phone.length !== 10) {
      invalidPhone = true;
    } else {
      invalidPhone = false;
    }
  }
  console.log('noPhones test', !escortAdInformation?.uniqueFields?.phoneNumber);

  const requiredFields = [];

  if (urlEmpty) {
    // requiredFields.push('URL cannot be empty');
  }
  if (noPhones) {
    // requiredFields.push('Escort Ad requires at least one phone');
    // requiredFields.push('Escort Ad requires st one phone');
  }
  if (invalidPhone) {
    requiredFields.push('Phone number must have exactly 10 digits');
  }

  // Check all web sources for required fields
  for (let i = 0; i < caseState.webSources.length; i++) {
    const webSource = caseState.webSources[i];
    // Make sure base required fields are present
    const fieldsToCheckFor = new Set([
      ...webSourceRequiredFields,
      ...(webSourceRequiredFieldsStructured[webSource.website.toLowerCase().replace(/ /g, '')] || []),
    ]);
    for (const field of fieldsToCheckFor) {
      const fieldValue = webSource.uniqueFields[field] || webSource[field];
      console.log({ field, fieldValue });
      if (!fieldValue) {
        const prettyFieldName = webSourceFieldLabel(field);
        requiredFields.push(`Web Source ${i + 1} is missing ${prettyFieldName}`);
        uniqueFieldsValid = false;
      }
    }
  }

  if (requiredFields.length !== 0) {
    // const message = `${requiredFields.join(', ')} (Escort Ad)`;
    const message = `${requiredFields.join(', ')}`;

    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message,
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }
  // console.log({ urlEmpty, noPhones, invalidPhone });
  // return false;
  return !urlEmpty && !noPhones && !invalidPhone && uniqueFieldsValid;
}

/**
 * Validates the web sources making sure they have a URL and all required fields.
 * Requirements for each web source type are defined in webSourceRequiredFieldsStructured.
 * @param {function} setAlert - Function to set the alert
 * @param {Array} webSources - Array of web sources
 */

// TODO use this Monday. Error to find ;)

export const webSourcesValidator = (setAlert, webSourceRequiredFieldsStructured, webSources = []) => {
  // const webSourceRequiredFieldsStructured = {
  //   'Backpage Ad': ['url', 'phoneNumber', 'phoneType', 'adLocation'],
  //   'Escort Ad': ['url', 'phoneNumber', 'phoneType', 'adLocation'],
  //   'Social Media': ['url'],
  //   'Website': ['url'],
  // };

  const invalidWebSources = webSources.filter(({ website, uniqueFields }) => {
    const requiredFields = webSourceRequiredFieldsStructured[website];

    if (!requiredFields) {
      return false;
    }

    return requiredFields.some(field => !uniqueFields[field]);
  });

  if (invalidWebSources.length) {
    const message = `${invalidWebSources.length} web source(s) are incomplete`;

    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message,
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  return true;
};
