import React, { useState, useEffect, useRef } from 'react';
import Cropper from 'react-easy-crop';
import * as Icons from 'react-icons/fa';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import Button from '../Button';
import { setCasePhoto } from '../../generated/graphql/mutations';
import imageCropHelper from '../../helpers/PhotoEditing/imageCropper';
import Spinner from '../Spinner';
import PopUpConfirmation from '../PopupConfirmation';

const ReportPhotoPicker = ({ caseId, photoId, s3Key, setCaseState }) => {
  const inputField = useRef(null);

  const [loading, setLoading] = useState('');
  const [showRemove, setShowRemove] = useState(false);
  const [removing, setRemoving] = useState(false);

  const [croppingPhoto, setCroppingPhoto] = useState(false);
  const [existingPhotoUrl, setExistingPhotoUrl] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedPhotoUrl, setCroppedPhotoUrl] = useState('');
  const [croppedPhoto, setCroppedPhoto] = useState(null);

  const placeholderImage = 'https://dummyimage.com/300x300/000/fff';

  useEffect(() => {
    const getPhotoUrl = async () => {
      const photo = await Storage.get(s3Key, {
        level: 'public',
        customPrefix: {
          public: '',
        },
      });

      setExistingPhotoUrl(photo);
    };

    if (s3Key) {
      getPhotoUrl();
    }
  }, [s3Key]);

  const renderImage = () => {
    if (s3Key && !existingPhotoUrl) {
      return (
        <div style={{ height: 300, width: 300 }} className="flex justify-center items-center">
          <Spinner />
        </div>
      );
    }

    return (
      <img
        src={croppedPhotoUrl || existingPhotoUrl || placeholderImage}
        alt={`case report ${photoId}`}
        height="300"
        width="300"
        className="contain"
      />
    );
  };

  const choosePhoto = () => {
    inputField.current.click();
  };

  const clearPhoto = () => {
    setPhotoUrl(null);
    setCroppingPhoto(false);
    setCroppedPhotoUrl(null);
    setCroppedPhoto(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const onPhotoSelected = event => {
    const url = window.URL.createObjectURL(event.target.files[0]);

    setPhotoUrl(url);
    setCroppingPhoto(true);
  };

  const validateAndUpload = async () => {
    setLoading(true);

    try {
      if (croppedPhoto) {
        const result = await Storage.put(`case_photos/${caseId}/${photoId}.png`, croppedPhoto, {
          level: 'public',
          contentType: 'image/png',
          customPrefix: { public: '' },
        });

        if (result && result.key) {
          saveCasePhoto(result.key);
        }
      }
    } catch (error) {
      console.log('ERROR UPLOADING PHOTO...', error);

      setLoading(false);
    }
  };

  const saveCasePhoto = async photoKey => {
    const params = { input: { id: caseId, photoId, s3Key: photoKey } };

    const {
      data: { setCasePhoto: res },
    } = await API.graphql(graphqlOperation(setCasePhoto, params));

    if (photoKey) {
      const newPhoto = await Storage.get(s3Key, {
        level: 'public',
        customPrefix: {
          public: '',
        },
      });

      setExistingPhotoUrl(newPhoto);
    } else {
      setExistingPhotoUrl(null);
    }

    setLoading(false);
    clearPhoto();
    setCaseState(res);
  };

  const deletePhoto = async () => {
    try {
      await Storage.remove(s3Key, {
        level: 'public',
        contentType: 'image/png',
        customPrefix: { public: '' },
      });

      await saveCasePhoto('');

      setRemoving(false);
      setShowRemove(false);
    } catch (error) {
      console.log('Error deleting photo: ', error);
    }
  };

  const onCropChange = newCrop => {
    setCrop(newCrop);
  };

  const onZoomChange = newZoom => {
    setZoom(newZoom);
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await imageCropHelper(photoUrl, croppedAreaPixels, 0);

    setCroppedPhotoUrl(croppedImage.blob);
    setCroppedPhoto(croppedImage.file);
  };

  return (
    <div className="m-4 flex flex-col justify-center items-center mr-4">
      <div className="flex justify-center items-center relative" style={{ width: 300, height: 300 }}>
        {croppingPhoto && photoUrl ? (
          <Cropper
            maxZoom={100}
            image={photoUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="rect"
            cropSize={{ width: 300, height: 300 }}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        ) : (
          renderImage()
        )}
      </div>

      <div className="flex flex-row mt-4" style={{ width: 300 }}>
        {!croppingPhoto ? (
          <>
            <Button linedBlue onClick={choosePhoto} className={`w-full text-base ${existingPhotoUrl ? 'mr-2' : ''}`}>
              SELECT &nbsp;
              <Icons.FaImages />
            </Button>

            {existingPhotoUrl ? (
              <Button linedRed onClick={() => setShowRemove(true)} className="w-full text-base ml-2">
                REMOVE &nbsp;
                <Icons.FaTrash />
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <Button linedBlue onClick={validateAndUpload} className="w-full text-base mr-2" loading={loading}>
              {loading ? 'SAVING' : 'SAVE'} &nbsp;
              {!loading && <Icons.FaSave />}
            </Button>

            <Button
              linedRed
              onClick={() => {
                clearPhoto();
              }}
              className="w-full text-base ml-2"
            >
              CANCEL &nbsp;
              <Icons.FaBan />
            </Button>
          </>
        )}

        <input type="file" className="hidden" onChange={onPhotoSelected} accept="image/*" ref={inputField} />
      </div>

      {photoUrl ? <div /> : null}

      {showRemove ? (
        <PopUpConfirmation
          title="Remove Photo"
          content={<div>Are you sure you want to remove this photo?</div>}
          onConfirm={() => {
            setRemoving(true);
            deletePhoto();
          }}
          onCancel={() => {
            setShowRemove(false);
          }}
          destructive
          confirmText="REMOVE"
          className="w-11/12 lg:w-auto"
          confirmLoading={removing}
          confirmLoadingText="REMOVING"
        />
      ) : null}
    </div>
  );
};

export default ReportPhotoPicker;
